@font-face {
    font-family: 'Nova Mono';
    src: url('assets/fonts/NovaMono-Regular.ttf');
}

@font-face {
    font-family: 'Muli';
    src: url('assets/fonts/Muli.ttf');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    ._sliding-menu {
        @apply fixed h-screen w-screen right-0 top-0 bg-lightBlue bg-ice-cliff bg-fixed bg-bottom bg-cover flex flex-col justify-center items-center py-12 translate-x-full will-change-transform transition-transform;
    }

    ._slide-in {
        @apply translate-x-0;
    }

    ._animate-slide-right {
        right: 0;
        opacity: 1;
        animation: 0.3s forwards ease-out slide-right;
    }

    .btn-rounded {
        @apply py-2 px-5 text-center rounded-md active:scale-90 active:brightness-90 hover:brightness-110 shadow-lg active:shadow-md transition-all font-display text-white;
    }

    .btn-bordered {
        @apply py-2 px-5 rounded-md shadow-md font-display border-2;
    }

    .card {
        @apply rounded-xl flex shadow-xl w-full h-full flex-wrap gap-4 justify-around items-center p-4 md:p-10 xl:p-12 bg-white sm:w-[90vw] md:w-[44rem] 2xl:w-[52rem] min-h-[60vh] md:min-h-[50vh] max-w-[90vw];
    }

    .paragraph {
        @apply flex flex-wrap w-full h-full sm:w-96 md:w-[32rem] 2xl:w-[52rem] gap-8;
    }

    .page {
        @apply py-16 flex flex-col items-center justify-between animate-page-appear;
    }

    .page:not(.bg-white) {
        @apply gap-16 md:gap-32;
    }

    .page.bg-white {
        @apply px-8 md:px-20 gap-16 md:gap-20 max-w-[1200px];
    }

    .text-input {
        @apply px-4 py-2 bg-white rounded-md shadow-md;
    }

    .input-container {
        @apply flex flex-wrap items-center gap-2 relative;
    }

    .option-container {
        @apply w-full text-sm flex justify-start items-center gap-4;
    }

    .options-group {
        @apply flex flex-col gap-2;
    }

    .error-message {
        @apply text-orange absolute -bottom-6 left-0 w-full text-center;
    }

    .index-figure {
        @apply w-max relative;
    }

    .item-details {
        @apply w-[90vw] flex flex-col justify-around items-center gap-6 bg-white rounded-xl shadow-xl p-4 md:p-8 md:w-[44rem] 2xl:w-[52rem];
    }

    ._lightbox-bg {
        @apply top-0 left-0 z-50 w-screen h-screen bg-black bg-opacity-50 fixed animate-page-appear;
    }

    .summary-item-number {
        @apply flex-none bg-blue w-6 h-6 flex text-xs text-white items-center justify-center rounded-full -ml-3 mr-3 -mt-2;
    }

    ._hidden-details {
        @apply flex text-white flex-col gap-4 justify-center items-center will-change-auto opacity-0 hover:opacity-100 transition duration-200 ease-in absolute top-0 left-0 p-8 w-full h-full bg-black bg-opacity-50;
    }

    ._roundedWithShadow {
        @apply rounded-xl shadow-xl;
    }

    ._roundedFull {
        @apply rounded-full shadow-xl;
    }

    img._logo {
        @apply drop-shadow w-12 h-auto md:w-16;
    }

    .screenframe-base {
        @apply w-full h-full object-cover transition-all ease-linear duration-[5000ms];
    }

    ._image-container {
        @apply block relative overflow-hidden max-w-[90vw] w-full h-56 md:h-64 mx-auto;
    }

    ._grid-item {
        @apply overflow-hidden max-w-[90vw] h-full w-full mx-auto;
    }

    ._text-padding {
        @apply p-4 md:p-8 flex flex-col gap-4 md:gap-8 justify-between items-center;
    }

    ._animate-zoom {
        @apply will-change-transform hover:scale-110 transition-all;
    }

    ._slider-container {
        @apply touch-none flex items-center justify-center touch-pan-x
    }
    
    ._slider-container._is-fullscreen {
        @apply top-0 left-0 z-50 w-screen h-screen bg-black bg-opacity-50 fixed;
    }

    ._slider-container._is-normal {
        @apply md:w-[70vw] h-72 md:h-96 relative w-full
    }

    ._arrow-button {
        @apply cursor-pointer text-white absolute text-4xl md:text-6xl z-50 top-0 h-full bg-black transition-all bg-opacity-20 
    }

    ._slider-container._is-normal ._arrow-button {
        @apply md:text-blue md:bg-transparent
    }
}

@layer base {
    * {
        @apply outline-none;
        -webkit-tap-highlight-color: transparent;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    header {
        @apply sticky z-50 top-0 w-full flex items-center justify-between gap-4 shadow-xl px-4 py-2 sm:px-8 sm:py-4 bg-white;
    }

    video {
        @apply will-change-auto animate-page-appear max-w-[90vw] m-auto w-72 md:w-96
    }

    main {
        @apply grow h-full bg-ice-cliff bg-fixed bg-bottom bg-cover w-full flex flex-col text-oxfordBlue justify-center items-center;
    }

    section {
        @apply w-full flex gap-16 md:gap-20 items-center justify-around flex-wrap even:flex-row-reverse animate-page-appear;
    }

    label {
        @apply text-sm;
    }

    legend {
        @apply text-lg;
    }

    textarea {
        @apply shadow-md bg-white border-2 border-lightBlue border-dashed h-20 rounded-xl w-full min-w-[26rem];
    }

    select {
        @apply text-sm bg-gray-100 rounded-md p-2 shadow max-h-[5rem];
    }

    option {
        @apply text-oxfordBlue;
    }

    option:selected {
        @apply bg-blue;
    }

    input[type='checkbox'] {
        @apply flex-none mt-1 appearance-none border border-orange rounded-sm bg-white checked:bg-orange checked:border-orange focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer block h-4 w-4;
    }

    input[type='radio'] {
        @apply flex-none mt-1 appearance-none rounded-full border border-orange bg-white checked:bg-orange checked:border-orange focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer block h-4 w-4
    }

    input[type='radio']:checked + label, input[type='checkbox']:checked + label {
        @apply text-orange;
    }

    article {
        @apply flex odd:flex-row-reverse gap-12 items-center flex-wrap w-full justify-around;
    }
}

@keyframes slide-left {
    100% {
        left: 0;
        opacity: 1;
    }
}

@keyframes slide-right {
    100% {
        right: -105%;
        opacity: 0;
    }
}


